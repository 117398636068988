import React, { SVGProps, forwardRef, Ref } from 'react';

type Props = {
  title?: React.ReactNode;
  titleId?: string;
} & SVGProps<SVGSVGElement>;

function SvgPowerBi({ title, titleId, ...props }: Props, svgRef?: Ref<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 50 50"
      width="100"
      height="100"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}

      <path d="M42,1H29c-1.103,0-2,0.897-2,2v9h-9c-1.103,0-2,0.897-2,2v8H9c-1.103,0-2,0.897-2,2v23c0,1.103,0.897,2,2,2h9h4h7h2h11	c1.103,0,2-0.897,2-2V3C44,1.897,43.103,1,42,1z M9,24h13l-0.001,23H9V24z M22,22h-4v-8h13l-0.001,33H24V24	C24,22.897,23.103,22,22,22z M41.999,47H33V14c0-1.103-0.897-2-2-2h-2V3h13L41.999,47z"></path>
    </svg>
  );
}

export default forwardRef(SvgPowerBi);
