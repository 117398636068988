import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from 'components/ui/Dialog';
import Button from 'components/ui/Button';
import { closeDialog, DIALOG_EVENT_SUBSCRIBE } from 'actions/uiActions';
import { EventModel } from 'models/event';
import useMessages from 'components/hooks/useMessages';
import { selectDialogByName } from 'selectors/uiSelectors';
import { AppStore } from 'store';
import Icon from 'components/ui/Icon';
import Link from 'components/utils/Link';
import { createEvent } from 'actions/eventActions';
import FormFieldset from 'components/form/FormFieldset';
import RadioField from 'components/form/RadioField';
import Editorial from 'components/ui/Editorial';
import DateInterval from 'components/utils/DateInterval';
import ExternalLink from 'components/ui/Icon/service/ExternalLink';
import ChevronRight from 'components/ui/Icon/actions/ChevronRight';

const DEFAULT_DIALOG = {
  open: false,
  event: undefined,
  onSuccess: undefined,
};

function selectDialog(store: AppStore) {
  return selectDialogByName(store, DIALOG_EVENT_SUBSCRIBE);
}

type DialogEventSubscribeProps = {
  event?: EventModel;
  onSuccess?(): void;
  open?: boolean;
};

function DialogEventSubscribe() {
  const dispatch = useDispatch();
  const { open, event, onSuccess }: DialogEventSubscribeProps =
    useSelector(selectDialog) || DEFAULT_DIALOG;

  const messages = useMessages(
    (t) => ({
      title: t('dialog.event_subscribe.add_title'),
      title_mandatory: t('dialog.event_subscribe.subscribe_title'),
      title_force_mandatory: t('dialog.event_subscribe.subscribe_title_force_mandatory'),
      subscribe: t('dialog.event_subscribe.subscribe'),
      subscribe_force_mandatory: t('dialog.event_subscribe.subscribe_force_mandatory'),
      yes: t('dialog.event_subscribe.action.label.yes'),
      no: t('dialog.event_subscribe.action.label.no'),
      close: t('label.close'),
      submit: t('feed.register'),
      campuses: (campuses: string[]) => t('events.campuses', { campuses: campuses.join(', ') }),
      dialog: {
        label: t('dialog.event_subscribe.add_title'),
        label_mandatory: t('dialog.event_subscribe.subscribe_title'),
        close: t('label.close'),
      },
      importantMessage: t('feed.register.mandatory'),
    }),
    [],
  );

  const [values, setValues] = useState<{ subscribe: 'yes' | 'no' }>({
    subscribe: 'yes',
  });

  const handleExit = useCallback(() => {
    dispatch(closeDialog(DIALOG_EVENT_SUBSCRIBE));
  }, [dispatch]);

  const handleClick = useCallback(async () => {
    if (event) {
      // Automatically subscribe to the event
      if (values.subscribe === 'yes') {
        dispatch(createEvent(event));
      }

      handleExit();

      if (onSuccess) {
        onSuccess();
      }
      if (event.cta) {
        openNewTab(event.cta.url);
      } else if (event.url) {
        openNewTab(event.url);
      }
    }
  }, [dispatch, event, handleExit, onSuccess, values.subscribe]);

  const openNewTab = (url: string): void => {
    window.open(url, '_blank');
  };

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues((values) => ({
      ...values,
      [name]: value,
    }));
  }, []);

  const campus =
    event && event.campuses && event.campuses.length > 0
      ? messages.campuses(event.campuses.map((campus) => campus.name))
      : null;

  const date = event ? (
    <DateInterval start={event.start} end={event.end} fullPreset="DATETIME_MED" />
  ) : null;

  return (
    <Dialog
      width="md"
      open={open}
      overtitle={
        event && event.cta?.addToCalendarMandatory
          ? event.cta?.inscriptionMandatory
            ? messages.title_force_mandatory
            : messages.title_mandatory
          : messages.title
      }
      title={event?.title}
      subtitle={
        <>
          {date}
          {campus}
        </>
      }
      messages={messages.dialog}
      onExit={handleExit}
      actions={
        <>
          <Button onClick={handleExit} style={{ marginRight: 'auto' }}>
            {messages.close}
          </Button>
          {event ? (
            event.url || event.cta ? (
              <Button kind="secondary" onClick={handleClick} icon={ExternalLink}>
                {event.cta ? event.cta.label : messages.submit}
              </Button>
            ) : (
              <Button kind="secondary" onClick={handleClick} icon={ChevronRight}>
                {messages.submit}
              </Button>
            )
          ) : null}
        </>
      }
      importantMessage={
        <>
          {event ? (
            event.cta && event.cta.inscriptionMandatory ? (
              <>{messages.importantMessage}</>
            ) : null
          ) : null}
        </>
      }
    >
      {event ? (
        <Editorial>
          <p>{event.description}</p>
        </Editorial>
      ) : null}
      <FormFieldset
        label={
          event && event.cta?.addToCalendarMandatory && event.cta?.inscriptionMandatory
            ? messages.subscribe_force_mandatory
            : messages.subscribe
        }
        labelProps={{ fontWeight: 'normal' }}
      >
        <RadioField
          name="subscribe"
          label={messages.yes}
          value="yes"
          checked={values.subscribe === 'yes'}
          onChange={handleChange}
        />
        {!(event && event.cta?.addToCalendarMandatory && event.cta?.inscriptionMandatory) ? (
          <RadioField
            name="subscribe"
            label={messages.no}
            value="no"
            checked={values.subscribe === 'no'}
            onChange={handleChange}
          />
        ) : null}
      </FormFieldset>
    </Dialog>
  );
}

export default DialogEventSubscribe;
