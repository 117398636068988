import { LocaleCode } from 'services/localeHelper';
import { EventSubtypeModel, EventProviderModel } from 'models/event';
import { Untrustable } from 'types';
import isArrayOf from 'types/isArrayOf';
import { TranslatorPrimitiveValues } from 'components/hooks/useTranslate';

export const USER_PROFILE_ENUM = ['staff', 'student', 'candidate', 'exec'] as const;
export type UserProfile = (typeof USER_PROFILE_ENUM)[number];

export type ViolationModel = {
  propertyPath: string;
  message: string;
};

export type ErrorModel = {
  code: string | null;
  status?: number | null;
  error?: string | null;
  violations?: ViolationModel[];
  values?: TranslatorPrimitiveValues;
  original?: any;
};

export type ConfigModel = {
  baseUrl: string;
  resetUrl: string;
  apiBaseUrl: string;
  useStatic: boolean;
  home: string;
  chatbotSecret?: string;
};

export type TokenModel = {
  token: string;
};

export function isTokenModel(token: any): token is TokenModel {
  return token && typeof token === 'object' && typeof token.token === 'string';
}

export type AuthCredentialsModel = { provider?: string; continueUrl?: string } & (
  | { email: string; password: string } // Firebase credentials
  // Firebase token
  | { token: string }
  // CDM email validation code
  | { email: string; verificationCode: string }
  // CDM code token
  | { codeToken: string }
);

export type DataModel = {
  sports?: TagModel[];
  campuses?: CampusModel[];
  countries?: CountryModel[];
  eventSubtypes?: EventSubtypeModel[];
  eventProviders?: EventProviderModel[];
  activityFroms?: TranslateModel[];
  privacyPolicy?: string | null;
  roomTypes?: RoomTypeModel[];
  roomReasons?: TranslateModel[];
  tags?: TagModel[];
  contracts?: TagModel[];
  skills?: TagModel[];
  interests?: TagModel[];
  candidateStatuses?: TranslateModel[];
  countryOptions?: OptionModel[];
  cursusOptions?: OptionModel[];
  civilityOptions?: OptionModel[];
};

export type Identity = {
  sub: string | null;
  exp: string | null;
  accessToken: string | null;
  scope?: string | null;
  from_sub?: string | number | null;
};

export function isIdentity(identity: object): identity is Identity {
  return 'sub' in identity && 'exp' in identity && 'accessToken' in identity;
}

export type PageModel = {
  title: string;
  slug: string;
  breadcrumbItems?: BreadcrumbItemModel[];
};

export type BreadcrumbItemModel = {
  id: number;
  title: string;
  slug: string | null;
};

export type EntityReferenceModel = {
  identifier: string;
  label: string;
};

export type CampusModel = {
  code: string;
  name: string;
  timezone?: string | null;
  hasRoomBooking?: boolean | null;
  hasSolidr?: boolean | null;
  affluenceToken?: string;
  affluenceUrl?: string;
  mapwizeVenue?: string;
  phoneButtonPanic?: string;
  isHidden?: boolean;
};

export type ProgramModel = {
  code: string;
  name: string;
  online?: boolean;
  logo?: string;
};

export function isProgramModel(subject: any): subject is ProgramModel {
  return subject && typeof subject === 'object' && 'code' in subject && 'name' in subject;
}

export type CandidateOpportunityModel = {
  code: string;
  name: string;
  secret?: string;
  hasForm?: boolean;
};

export function isCandidateOpportunityModel(subject: any): subject is CandidateOpportunityModel {
  return (
    subject &&
    typeof subject === 'object' &&
    'code' in subject &&
    'name' in subject &&
    'secret' in subject &&
    'hasForm' in subject
  );
}

export type StudyYearModel = {
  code: string;
  name: string;
};

export type RoomModel = {
  identifier: string;
  title: string;
  minuteStep: number | null;
  hourMin: number | null;
  hourMax: number | null;
  code: string | null;
  bookable: boolean;
  image: string | null;
  description: string;
};

export type RoomTypeModel = {
  code: string;
  name: string;
};

export type TranslateModel = {
  code: string;
  label: string;
};

export type OptionModel = {
  value: string;
  label: string;
  options: OptionModel[] | null;
};

export type CtaModel = {
  url: string;
  label: string;
  addToCalendarMandatory: boolean;
  inscriptionMandatory: boolean;
};

export type TagModel = {
  title: string;
  slug: string;
  icon: string | null;
  important: boolean;
};

export function isTagModel(tag: any): tag is TagModel {
  return tag && typeof tag === 'object' && 'title' in tag && 'slug' in tag;
}

export type UserAddressModel = Untrustable<{
  type: string;
  label: string;
  address1: string;
  address2: string;
  address3: string;
  zipcode: string;
  city: string;
  country: string;
  phone: string;
  mobile: string;
  email: string;
}>;

export type UserSettingsModel = {
  campus: string | CampusModel | null;
  locale: LocaleCode | null;
  timezone: string | null;
  favoriteServices: number[];
  hiddenBlocks: string[];
  shownBlocks: string[];
  calendars: string[];
} & Untrustable<{
  description?: string;
  book?: string;
  movie?: string;
  music?: string;
  game?: string;
  facebook?: string;
  twitter?: string;
  linkedin?: string;
  skills?: TagModel[];
  interests?: TagModel[];
  hasReadPolicy?: boolean;
  hasSeenProfileSwitcher?: boolean;
  freshchatRestoreId: string;
}>;

export type ExternalDataModel = Untrustable<{
  photo: string;
  title: string;
  service: string;
  direction: string;
  phone1: string;
  phone2: string;
  location: string;
}>;

export type AdditionalDataModel = Untrustable<{
  mail: string;
  field_first_name: string;
  field_last_name: string;
  field_birth_date: string;
  field_nationality: string;
  field_address_1: string;
  field_address_2: string;
  field_zip_code: string;
  field_city: string;
  field_residence_country: string;
  field_telephone: string;
  field_current_study_level: string;
  field_current_diploma: string;
  field_graduation_year: string;
  field_school_name: string;
  field_professional_experience: string;
}>;

export type UserModel = {
  userId: string | null;
  upn: string | null;
  firstName: string | null;
  lastName: string | null;
  campus: CampusModel | null;
  program: ProgramModel | null;
  studyYear: StudyYearModel | null;
  profile: string | null;
  status: string | null;
  settings: UserSettingsModel;
  externalData: ExternalDataModel | null;
  additionalData: AdditionalDataModel | null;
  addresses?: UserAddressModel[] | null;
  title?: string | null;
  service?: string | null;
  direction?: string | null;
  phone1?: string | null;
  phone2?: string | null;
  photo?: string | null;
  location?: string | null;
  redirectToken?: string | null;
  activities: number[];
};

export function isUserModel(subject: any): subject is UserModel {
  return subject && typeof subject === 'object' && 'userId' in subject;
}

export type DidocModel = {
  label: string;
  url: string;
  year: string | null;
};

export type BehaviorModel<B> = B extends {} ? B & { family: string } : { family: null };

export type HelpModel = {
  title: string | null;
  text: string | null;
  order?: number | null;
  position?: string | null;
  isFixed: boolean;
};

export type NodeModel<B = null> = {
  family: string;
  identifier: number;
  code: string;
  enabled: boolean;
  hidden: boolean;
  behavior: BehaviorModel<B>;
  content: NodeModel[];
  help: HelpModel | null;
};

export type PageBehaviorModel = {
  id: number;
  title: string;
  slug: string;
  layout: string;
  status: 'ok' | 'lazy';
  zone?: string;
  breadcrumbItems?: BreadcrumbItemModel[];
};

export type CertificateModel = {
  id: string;
  code: string;
  label: string;
  title: string;
  url?: string;
  completion?: 0 | 50 | 100;
  image?: string;
  courses?: CourseModel[] | null;
  channels?: ChannelModel[] | null;
};

export function isCertificateModel(subject: any): subject is CertificateModel {
  return (
    subject &&
    typeof subject === 'object' &&
    'id' in subject &&
    'code' in subject &&
    'label' in subject &&
    'title' in subject
  );
}

export type CourseModel = {
  id: string;
  title: string;
  completion?: 0 | 50 | 100;
  url?: string;
  duration?: number;
};

export type ChannelModel = {
  identifier: string;
  label: string;
  url: string;
  icon?: string;
};

export type CertificateListResponse = {
  items: CertificateModel[];
};

export function isCertificateListResponse(subject: any): subject is CertificateListResponse {
  return subject && typeof subject === 'object' && isArrayOf(subject.items, isCertificateModel);
}

export type CertificateItemResponse = {
  item: CertificateModel;
};

export function isCertificateItemResponse(subject: any): subject is CertificateItemResponse {
  return subject && typeof subject === 'object' && isCertificateModel(subject.item);
}

export type VideoModel = {
  identifier: number;
  title: string;
  link: string;
  subtitle: string | null;
};

export type ToastModel = {
  uid?: string;
  title?: string;
  message: string;
  start: string;
  end: string;
  active: boolean;
};

export type PagerModel<T> = {
  currentPage: number;
  itemsPerPage: number;
  totalPages: number;
  totalItems: number;
  items: T[];
  tags?: TagModel[];
};

function isPagerModel(subject: any): subject is PagerModel<any> {
  return (
    subject &&
    typeof subject === 'object' &&
    'currentPage' in subject &&
    'itemsPerPage' in subject &&
    'totalPages' in subject &&
    'totalItems' in subject &&
    'items' in subject &&
    Array.isArray(subject.items)
  );
}

export function isPagerModelOf<T>(
  subject: unknown,
  isT: (item: unknown) => item is T,
): subject is PagerModel<T> {
  return isPagerModel(subject) && subject.items.every(isT);
}

export function makeIsPagerModelOf<T>(isT: (item: any) => item is T) {
  return (subject: any): subject is PagerModel<T> => {
    return isPagerModelOf(subject, isT);
  };
}

export type ToastType = {
  title?: string;
  uid?: string;
  icon?: string;
  messageRenderer?: string;
  show: boolean;
  sticky: boolean;
  timezone?: string;
  referenceTimezone?: string;
  message?: string;
  to?: string;
  autoclose?: number; // Number of seconds before autoclosing the toast
  kind?: 'secondary' | 'primary';
};

export type BookingModel = {
  id?: string;
  room_id: string;
};

export type DocumentRequestModel = {};

export type RoomRequestModel = {};

export type FileModel = {
  identifier: number;
  url: string;
  name: string;
};

export type GradeModel = {
  id: string;
  title: string;
  value: string;
};

export type GradePeriodModel = {
  title: string;
  marks: GradeModel[];
  year?: string | null;
  program?: string | null;
  programCode?: string | null;
  period?: string | null;
  periodCode?: string | null;
};

export type HelpStep = HelpModel & {
  id: string;
  selector: string;
};

export type FeedItemModel = {
  title: string;
  url: string;
  source?: string;
  date?: string;
};

export type LinkModel = {
  url: string;
  label: string | null;
};

export function isLinkModel(subject: any): subject is LinkModel {
  return subject && typeof subject === 'object' && 'url' in subject;
}

export type CountryModel = {
  code: string;
  label: string;
};
