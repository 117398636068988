import AdeBooking from './AdeBooking';
import Aeprof from './Aeprof';
import Affluence from './Affluence';
import Agenda from './Agenda';
import Arpege from './Arpege';
import Attestations from './Attestations';
import BadgesQrCodes from './BadgesQrCodes';
import Brightspace from './Brightspace';
import Canel from './Canel';
import CareerCenter from './CareerCenter';
import Cervoprint from './Cervoprint';
import ComiteEntreprise from './ComiteEntreprise';
import CompassGroup from './CompassGroup';
import ConciergeriePedagogique from './ConciergeriePedagogique';
import Conference from './Conference';
import Connect from './Connect';
import CourseAdvisor from './CourseAdvisor';
import Courses from './Courses';
import Default from './Default';
import Download from './Download';
import Easyrecrue from './Easyrecrue';
import Elearning1 from './Elearning1';
import Elearning2 from './Elearning2';
import Elearning from './Elearning';
import Emlyon from './Emlyon';
import Emlyonx from './Emlyonx';
import Emquery from './Emquery';
import Emways from './Emways';
import Eng from './Eng';
import Espace454 from './Espace454';
import Events from './Events';
import ExternalLink from './ExternalLink';
import Finance from './Finance';
import Foodi from './Foodi';
import Forever from './Forever';
import Goodies from './Goodies';
import Grades from './Grades';
import Graduation from './Graduation';
import Help from './Help';
import Iael from './Iael';
import Impressions from './Impressions';
import Info2000 from './Info2000';
import Internship from './Internship';
import JobTeaser from './JobTeaser';
import KiosqueRh from './KiosqueRh';
import Lang from './Lang';
import LearningHub from './LearningHub';
import Logout from './Logout';
import Mail from './Mail';
import Makerslab from './Makerslab';
import Mantis from './Mantis';
import Media from './Media';
import MobilityOnline from './MobilityOnline';
import MyEm from './MyEm';
import Mybenefeat from './Mybenefeat';
import Mysupport from './Mysupport';
import News from './News';
import Notilus from './Notilus';
import Onedrive from './Onedrive';
import Outlook from './Outlook';
import Pds from './Pds';
import Pin from './Pin';
import Plane from './Plane';
import Play from './Play';
import PowerBi from './PowerBi';
import Print from './Print';
import Profile from './Profile';
import Roombooking from './Roombooking';
import Scolarest from './Scolarest';
import Search from './Search';
import Security from './Security';
import Solidr from './Solidr';
import SportEquipment from './SportEquipment';
import Sport from './Sport';
import Sunrise from './Sunrise';
import SyllabusBuilder from './SyllabusBuilder';
import Teams from './Teams';
import Toutesmesaides from './Toutesmesaides';
import Unit4 from './Unit4';
import Via from './Via';
import Waze from './Waze';
import Wireless from './Wireless';
import Wooclap from './Wooclap';
import Zoom from './Zoom';

const icons = {
  'ade-booking': AdeBooking,
  aeprof: Aeprof,
  affluence: Affluence,
  agenda: Agenda,
  arpege: Arpege,
  attestations: Attestations,
  'badges-qr-codes': BadgesQrCodes,
  brightspace: Brightspace,
  canel: Canel,
  'career-center': CareerCenter,
  cervoprint: Cervoprint,
  'comite-entreprise': ComiteEntreprise,
  'compass-group': CompassGroup,
  'conciergerie-pedagogique': ConciergeriePedagogique,
  conference: Conference,
  connect: Connect,
  'course-advisor': CourseAdvisor,
  courses: Courses,
  default: Default,
  download: Download,
  easyrecrue: Easyrecrue,
  elearning1: Elearning1,
  elearning2: Elearning2,
  elearning: Elearning,
  emlyon: Emlyon,
  emlyonx: Emlyonx,
  emquery: Emquery,
  emways: Emways,
  eng: Eng,
  espace454: Espace454,
  events: Events,
  'external-link': ExternalLink,
  finance: Finance,
  foodi: Foodi,
  forever: Forever,
  goodies: Goodies,
  grades: Grades,
  graduation: Graduation,
  help: Help,
  iael: Iael,
  impressions: Impressions,
  info2000: Info2000,
  internship: Internship,
  'job-teaser': JobTeaser,
  'kiosque-rh': KiosqueRh,
  lang: Lang,
  'learning-hub': LearningHub,
  logout: Logout,
  mail: Mail,
  makerslab: Makerslab,
  mantis: Mantis,
  media: Media,
  'mobility-online': MobilityOnline,
  'my-em': MyEm,
  mybenefeat: Mybenefeat,
  mysupport: Mysupport,
  news: News,
  notilus: Notilus,
  onedrive: Onedrive,
  outlook: Outlook,
  pds: Pds,
  pin: Pin,
  plane: Plane,
  play: Play,
  powerBi: PowerBi,
  print: Print,
  profile: Profile,
  roombooking: Roombooking,
  scolarest: Scolarest,
  search: Search,
  security: Security,
  solidr: Solidr,
  'sport-equipment': SportEquipment,
  sport: Sport,
  sunrise: Sunrise,
  'syllabus-builder': SyllabusBuilder,
  teams: Teams,
  toutesmesaides: Toutesmesaides,
  unit4: Unit4,
  via: Via,
  waze: Waze,
  wireless: Wireless,
  wooclap: Wooclap,
  zoom: Zoom,
};

export default icons;
